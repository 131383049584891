main {
    margin-top: 80px;
    padding-top: 30px;

    @include media-breakpoint-down(md) {
        padding-top: 45px;
    }

    @include media-breakpoint-down(xs) {
        padding-top: 30px;
    }
}

.main-container {
    width: 100%;

    @include lg {
        margin-left: 60px;
        width: calc(100% - 60px);
    }

    &.__bg-grey {
        background-color: $c-lgrey;
        padding-bottom: 90px;
    }
}

.content {
    padding-top: 60px;
    padding-bottom: 90px;

    @include media-breakpoint-down(md) {
        padding-top: 30px;
        padding-bottom: 60px;
    }

    section {
        padding-top: 60px;

        @include media-breakpoint-down(xs) {
            padding-top: 30px;
        }
    }
}

.home-title {
    margin-bottom: 50px;

    @include media-breakpoint-up(lg) {
        margin-left: 30px;
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
    }

    h1 {
        font-size: 3.8em;
        height: 0;
        line-height: 1.2em;
        opacity: 0;
        visibility: hidden;

        @include media-breakpoint-down(md) {
            font-size: 2.4em;
        }
    }

    h2 {
        font-size: 3.5em;
        line-height: 1.33em;

        @include media-breakpoint-down(md) {
            font-size: 5em;
        }

        @include media-breakpoint-down(xs) {
            font-size: 2.5em;
        }

        &.title-lg {
            font-size: 5.5em;
            line-height: 1.2em;

            @include media-breakpoint-down(md) {
                font-size: 5em;
            }

            @include media-breakpoint-down(xs) {
                font-size: 3em;
            }
        }
    }

    p {
        font-size: 2.5em;
        line-height: 1.4em;

        @include media-breakpoint-down(xs) {
            font-size: 1.6em;
        }
    }
}
