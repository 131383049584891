@mixin font($ff, $fw) {
    font-family: $ff;
    font-weight: $fw;
}

@mixin hover() {
    &:focus,
    &:active,
    &:hover {
        @content;
    }
}

@mixin hover-focus-active {
    &:hover,
    &:focus,
    &:active {
        @content;
    }
}
