// Modal
.modal {
    &-wrap {
        bottom: 0;
        display: flex;
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;

        @include lg {
            left: 60px;
        }
    }

    &-dialog {
        margin: auto;
        max-width: 1140px;
        pointer-events: auto;
        width: 100%;

        @include lg {
            padding: 0 10px;
        }
    }

    &-content {
        border: none;
        border-radius: 0;
    }

    &-header,
    &-body,
    &-footer {
        padding: 15px;

        @include sm {
            padding: 20px;
        }

        @include md {
            padding: 25px;
        }

        @include lg {
            padding: 30px;
        }
    }

    &-body {
        padding-top: 5px;
    }

    &-header {
        align-items: center;
        background: $c-light;
        border-bottom: 0;
        margin-bottom: 8px;
        padding-bottom: 0;
        position: sticky;
        top: 0;
        z-index: 100;

        i.__counter {
            background-color: red;
            border-radius: 50%;
            color: #fff;
            font-size: 12px;
            height: 26px;
            line-height: 24px;
            margin-right: 8px;
            text-align: center;
            width: 26px;
        }

        &.__divider {
            border-bottom: 1px solid $c-dgrey;
            box-sizing: content-box;
            min-height: 32px;
            padding: 20px;
        }
    }

    &-title {
        font-family: $font-bold;
        font-size: 20px;
        margin-right: 8px;
    }

    &-close {
        align-self: flex-start;
        margin-left: auto;
        position: relative;
        z-index: 99;

        .close {
            color: $c-bread;
            font-weight: normal;
            opacity: 1;
            transition: $time linear;

            &::before {
                content: '\0050';
                font-family: $font-lenovo-shop;
                font-size: 20px;
                position: relative;

                @include sm {
                    font-size: 30px;
                }
            }

            @include hover {
                color: $c-default;
            }
        }
    }

    &-button {
        align-items: center;
        background-color: $c-exclusive;
        border: 0;
        border-radius: 2px;
        color: $c-default;
        cursor: pointer;
        display: flex;
        font-size: 16px;
        height: 50px;
        justify-content: center;
        line-height: 1;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        outline: 0;
        padding: 0 40px;
        transition: $time ease-in-out;

        &:hover {
            box-shadow: 0 5px 18px 2px rgba($c-exclusive, .2);
        }
    }

    &-message {
        display: none;
        text-align: center;

        &-icon {
            align-items: center;
            background-color: $c-dgrey;
            border-radius: 100%;
            display: flex;
            height: 50px;
            justify-content: center;
            margin-bottom: 15px;
            margin-left: auto;
            margin-right: auto;
            width: 50px;

            &::before {
                color: $c-light;
                content: '';
                font-family: $font-lenovo-shop;
                font-size: 24px;
                line-height: 1;
            }

            &.__success {
                background-color: $c-success;

                &::before {
                    content: '\004d';
                }
            }

            &.__error {
                background-color: $c-corp;

                &::before {
                    content: '\004f';
                }
            }
        }

        p {
            font-size: 22px;
            line-height: 1.25;
            margin-bottom: 25px;

            span {
                display: block;
            }
        }
    }


    &.__side {
        &.__left {
            .modal-dialog {
                left: -280px;
                transition: left $time*1.5 ease-out;
            }

            &.show .modal-dialog{
                left: 0;
            }
        }

        &.__right {
            .modal-dialog {
                right: -280px;
                transition: right $time*1.5 ease-out;
            }
            &.show .modal-dialog{
                right: 0;
            }

            .modal-header {
                flex-direction: row-reverse;

                .modal-close {
                    align-items: flex-end;
                    margin-left: 0;
                    margin-right: auto;
                }
            }
        }

        .modal-dialog {
            height: 100%;
            margin: auto;
            min-width: 280px;
            position: fixed;
            transform: translate3d(0%, 0, 0);
            transition: opacity $time*1.5 ease-out;
            width: 70%;
        }

        .modal-content {
            height: 100%;
            overflow-y: auto;
        }

        .modal-header {
            height: 43px;
            margin-bottom: 0;
            min-height: 43px;
            padding-bottom: 0;
            padding-top: 10px;
        }

        .modal-title {
            font-size: 22px;
            font-family: $font-reg;
        }

        .modal-close {
            align-self: center;

            .close::before {
                font-size: 22px;
            }
        }
    }
}

// Tooltip
.ui-tooltip-bottom {
    background-color: $c-light;
    box-shadow: 0 5px 18px 2px rgba($c-shadow, .3);
    color: $c-default;
    font-size: 14px;
    line-height: 48px;
    padding: 0 5px;
    position: absolute;
    text-align: center;
    z-index: 99;
    width: auto;

    &::before {
        border-left: 13px solid transparent;
        border-right: 13px solid transparent;
        border-bottom: 8px solid $c-light;
        top: -8px;
        content: '';
        display: block;
        height: 0;
        left: calc(50% - 13px);
        position: absolute;
        width: 0;
    }
}

.ui-tooltip-top {
    background-color: $c-light;
    box-shadow: 0 5px 18px 2px rgba($c-shadow, .3);
    color: $c-default;
    font-size: 14px;
    line-height: 48px;
    padding: 0 8px;
    position: absolute;
    text-align: center;
    z-index: 99;
    width: auto;

    &::before {
        border-left: 13px solid transparent;
        border-right: 13px solid transparent;
        border-top: 8px solid $c-light;
        bottom: -8px;
        content: '';
        display: block;
        height: 0;
        left: calc(50% - 13px);
        position: absolute;
        width: 0;
    }
}

.ui-tooltip-left {
    background-color: $c-light;
    box-shadow: 0 5px 18px 2px rgba($c-shadow, .3);
    color: $c-default;
    font-size: 14px;
    line-height: 48px;
    min-width: 100px;
    padding: 0 5px;
    position: absolute;
    text-align: center;
    z-index: 99;
    width: auto;

    &::before {
        border-bottom: 13px solid transparent;
        border-top: 13px solid transparent;
        border-left: 8px solid $c-light;
        top: calc(50% - 13px);
        right: -8px;
        content: '';
        display: block;
        height: 0;
        position: absolute;
        width: 0;
    }
}

// object-fit: cover - fallback styles
.objectfit-container {
    &.objectfit-bg {
        background-position: center center;
        background-size: cover;
        position: relative;

        .objectfit-img {
            display: none;
        }
    }
}

// image contain (object-fit: contain emulation)
.image-contain {
    height: 100%;
    position: relative;
    width: 100%;

    img {
        left: 50%;
        max-height: 100%;
        max-width: 100%;
        position: absolute;
        top: 50%;
        transform: translate(-50%,-50%);
    }
}

// Buttons
.button {
    background-color: transparent;
    border: solid 1px $c-dgrey;
    border-radius: 2px;
    color: $c-default;
    display: inline-block;
    font-size: 1.8em;
    height: 50px;
    line-height: 48px;
    max-width: 100%;
    padding: 0 20px;
    text-align: center;
    transition: all $time ease-in-out;
    width: auto;

    @include hover {
        background-color: $c-corp;
        border: solid 1px $c-corp;
        box-shadow: 0 5px 18px 2px rgba($c-scarlet, .2);
        color: $c-light;
    }

    span {
        position: relative;

        &::before {
            background-size: cover;
            content: '';
            display: block;
            left: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
		}
    }

    &.__yellow {
        border-color: $c-yell;
        background-color: $c-yell;
        color: $c-default;

        @include hover {
            background-color: $c-yell-act;
            border-color: $c-yell-act;
            box-shadow: 3px 4px 18px 2px $shd-yell;
        }
    }

    &.__black {
        @include hover {
            background-color: $c-dark;
            border-color: $c-dark;
            box-shadow: 0 5px 18px 2px rgba($c-dark, .2);
            color: $c-light;
        }
    }

    &.__red {
        border-color: $c-corp;
        background-color: $c-corp;
        color: $c-light;
    }

    &.__active {
        border: 1px solid $c-corp;
    }

    &.__shop {
        font-size: 1.4em;
        height: 40px;
        line-height: 38px;

        @include media(1279) {
            font-size: 1.3em;
            padding: 0 15px;
        }
    }

    &.__small {
        font-size: 1.4em;
        height: 40px;
        line-height: 38px;
    }

    &.__small-modal {
        padding: 0 8px;
        margin-right: 8px;
        font-size: 1.4em;
        height: 30px;
        line-height: 28px;
    }

    &.__icon {
        color: $c-bread;
        display: flex;
        font-size: 12px;
        height: 40px;
        line-height: 38px;
        border: none;
        box-shadow: none;
        padding: 0;
        background-color: transparent;
        transition: all $time ease-in-out;

        &:hover {
            color: $c-default;
            box-shadow: none;
            background-color: transparent;
        }

        i {
            font-size: 16px;
            margin-top: 3px;
            margin-right: 8px;
        }
    }
}

.button-dropdown {
    background-color: transparent;
    border-radius: 2px;
    display: inline-block;
    font-size: 1.8em;
    height: 50px;
    line-height: 48px;
    max-width: 100%;
    text-align: center;
    transition: all $time ease-in-out;
    width: auto;
    padding: 0;
    border: 0;
    color: $c-link;

    i {
        margin-left: 8px;
    }

    &.__small {
        font-size: 1.4em;
        height: 40px;
        line-height: 38px;
    }
}

.link-info {
    font-size: 16px;
    width: 15px;
    height: 15px;
    margin-left: 5px;
    display: inline-block;
    text-indent: -9999px;
    background: url(#{$assets-url}/images/svg/info_icon.svg) center center no-repeat;
    background-size: cover;
    transition: $time linear;
    position: relative;
    top: 6px;

    @include hover {
        background: url(#{$assets-url}/images/svg/info_icon_hover.svg) center center no-repeat;
    }

    &[data-tooltip] {
        &::after {
            background: $c-light;
            box-shadow: 0px 5px 18px 2px rgba($c-shadow, 0.35);
            content: attr(data-tooltip);
            display: block;
            font-size: 12px;
            left: 50%;
            opacity: 0;
            min-width: 200px;
            padding: 12px;
            position: absolute;
            text-indent: 0;
            top: 27px;
            transform: translate(-50%, 0);
            transition: opacity $time;
            visibility: hidden;
            z-index: 1;

            @include md {
                left: 30px;
                min-width: 300px;
                top: 50%;
                transform: translate(0, -50%);
            }
        }

        &::before {
            border-bottom: 8px solid $c-light;
            border-left: 13px solid transparent;
            border-right: 13px solid transparent;
            content: '';
            display: block;
            height: 0;
            left: 50%;
            opacity: 0;
            position: absolute;
            top: 20px;
            transform: translate(-50%, 0);
            transition: opacity $time;
            visibility: hidden;
            width: 0;
            z-index: 2;

            @include md {
                border-bottom: 13px solid transparent;
                border-right: 8px solid $c-light;
                border-top: 13px solid transparent;
                left: 9px;
                top: 50%;
                transform: translate(0, -50%);
            }
        }

        &:hover,
        &:focus {
            &::before,
            &::after {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.info-button {
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    height: 16px;
    line-height: 1;
    margin-left: 3px;
    position: relative;
    text-indent: -9999px;
    width: 16px;

    &::before {
        color: $c-link;
        content: '\0053';
        font-family: $font-lenovo-shop;
        left: 50%;
        position: absolute;
        text-indent: 0;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &::after {
        border-radius: 50%;
        content: '';
        height: 25px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 25px;
    }

    &.__flexible {
        .info-button-tooltip {

            &::before {
                display: none;
            }

            &-triangle {
                border-left: 13px solid transparent;
                border-right: 13px solid transparent;
                border-top: 8px solid $c-light;
                display: block;
                height: 0;
                left: calc(50% - 13px);
                padding-right: 0;
                position: absolute;
                bottom: -7px;
                width: 0;
            }
        }
    }

    &-tooltip {
        background-color: $c-light;
        bottom: 30px;
        box-shadow: 0 5px 18px 2px rgba($c-shadow,.35);
        display: flex;
        font-family: $font-reg;
        font-size: 12px;
        left: 50%;
        line-height: 18px;
        min-width: 265px;
        opacity: 0;
        padding: 10px 15px;
        position: absolute;
        text-align: center;
        text-indent: initial;
        transform: translateX(-50%);
        transition: opacity .2s;
        visibility: hidden;
        width: auto;
        z-index: 9;

        &::before {
            border-left: 13px solid transparent;
            border-right: 13px solid transparent;
            border-top: 8px solid $c-light;
            content: '';
            display: block;
            height: 0;
            left: calc(50% - 13px);
            position: absolute;
            bottom: -7px;
            width: 0;
        }
    }

    @include hover {
        &::before {
            content: '\0054';
        }

        .info-button-tooltip {
            opacity: 1;
            visibility: visible;
        }
    }
}

// Rating Select
.rating-select {
    display: inline-flex;
    flex-direction: row-reverse;
    justify-content: flex-end;

    > input {
        display: none;
    }

    > label {
        color: $c-yell;
        cursor: pointer;
        font-family: $font-icon;
        font-size: 4em;
        padding-right: 10px;
        position: relative;

    // empty star
        &::before {
            content: $fa-var-star-o;
            left: 0;
            opacity: 1;
            position: absolute;
            top: 0;
        }

    // filled star
        &::after {
            content: $fa-var-star;
            opacity: 0;
            transition: opacity $time linear;
        }

    // hovering stars
        &:hover::after,
        &:hover ~ label::after {
            opacity: 1;
        }
    }

    // checked, not hovering stars
    &:not(:hover) > :checked ~ label::after {
        content: $fa-var-star;
        opacity: 1;
    }
}

// Rating Stars
.rating-container {
    align-items: center;
    display: flex;
    flex-shrink: 0;
}

.rating-stars {
    color: $c-exclusive;
    flex-shrink: 0;
    font-size: 0;
    margin-right: 7px;
    white-space: nowrap;

    @include sm {
        margin-right: 10px;
    }

    .fa {
        font-size: 14px;
        margin-right: 0px;

        @include media-min(376) {
            margin-right: 3px;
        }
    }
}

.rating-txt {
    color: $c-bread;
    font-size: 14px;
}

// Video microdata
.video-schema-meta {
    display: none;
}

.cookie-bar {
    align-items: flex-start;
    background: $c-yell;
    border: 1px solid $c-dgrey;
    bottom: 0;
    color: $c-default;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    opacity: 1;
    padding: 20px 10px;
    position: fixed;
    right: 0;
    transition: $time all ease-in-out;
    visibility: visible;
    width: 100%;
    z-index: 201;

    @include lg {
        align-items: center;
        flex-direction: row;
        justify-content: center;
        padding: 10px 30px;
    }

    &.__hide {
        height: 0;
        opacity: 0;
        visibility: hidden;
    }

    &-text {
        font-size: 14px;
        margin-bottom: 15px;

        @include lg {
            font-size: 16px;
            margin-bottom: 0;
            margin-right: 20px;
        }
    }

    &-close {
        align-items: center;
        background: $c-link;
        border-radius: 2px;
        border: none;
        color: $c-light;
        display: inline-block;
        font-size: 14px;
        height: 30px;
        justify-content: center;
        line-height: 30px;
        padding: 0 10px;
        transition: all $time;

        @include lg {
            padding: 0 15px;
        }

        &:hover {
            background: $c-default;
        }
    }

    a {
        color: $cLink;
        white-space: nowrap;
    }
}
