@mixin _typography-header-size($mobile, $tablet, $desktop) {
    font-size: $mobile;

    @include sm {
        font-size: $tablet;
    }

    @include md {
        font-size: $desktop;
    }
}

.title-page {
    color: $c-default;
    font-family: $font-reg;
    line-height: 1.2em;
    text-align: left;

    @include lg {
        margin-left: 30px;
    }
}

.page-title {
    @extend .title-page;
    @include _typography-header-size(3em, 5em, 3.5em);
}

.section-title {
    @extend .title-page;
    @include _typography-header-size(2.5em, 3.5em, 3em);
}

.third-title {
    @extend .title-page;
    @include _typography-header-size(2em, 3em, 2.5em);
}

.__typography {
    p,
    h2,
    ul,
    ol,
    li,
    blockquote {
        color: $c-default;
        font-family: $font-light;
        font-size: 16px;
        line-height: 1.5em;
        margin-bottom: 35px;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: $font-reg;
        font-size: 25px;
        margin-bottom: 20px;
    }

    ul {
        padding-left: 0;

        @include sm {
            padding-left: 20px;
        }

        li {
            margin-bottom: 15px;
            padding-left: 20px;
            position: relative;

            &::before {
                border: 1px solid $c-corp;
                border-radius: 50%;
                content: '';
                display: block;
                height: 7px;
                left: 0;
                position: absolute;
                top: 10px;
                width: 7px;
            }
        }
    }

    ol {
        counter-reset: myCounter;
        list-style: none;
        padding-left: 0;

        @include sm {
            padding-left: 10px;
        }

        li {
            margin-bottom: 15px;
            padding-left: 30px;
            position: relative;

            &::before {
                color: $c-corp;
                content: counter(myCounter) '.';
                counter-increment: myCounter;
                display: inline-block;
                font-family: $font-bold;
                left: 0;
                position: absolute;
                text-align: right;
                top: 0;
                width: 24px;
            }
        }
    }

    blockquote {
        background-color: $c-lgrey;
        font-family: $font-bold;
        font-size: 16px;
        line-height: 1.5em;

        @include sm {
            font-size: 19px;
            line-height: 1.3em;
        }

        .post-article & {
            left: -10px;
            padding: 30px;
            position: relative;
            width: calc(100% + 20px);

            @include lg {
                left: -34.131737%;
                padding: 50px;
                padding-left: 34.131737%;
                width: 134.131737%;
            }
        }
    }

    a {
        color: $c-link;
        text-decoration: underline;
        transition: $time ease-in-out;

        @include hover {
            color: $c-corp;
        }
    }

    img {
        display: block;
        margin: 0 auto 30px;
        max-width: 100%;

        .post-article & {
            display: block;
            left: 0;
            max-width: 100%;
            position: relative;
            width: 100%;

            @include lg {
                left: -34.131737%;
                max-width: none;
                width: 134.131737%;
            }
        }
    }
}
