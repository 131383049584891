:root {
  --c-light: #fff;
  --c-default: #000;
  --c-corp: #f00;
  --c-bread: #777;
  --c-lgrey: #f8f8f8;
  --c-dgrey: #ccc;
}

$c-none: transparent;
$c-light: #fff;
$c-lgrey: #f8f8f8;
$c-lgrey2: #f2f2f2;
$c-lgrey3: #f9f9f9;
$c-grey: #eee;
$c-mgrey: #e2e2e2;
$c-dgrey: #ccc;
$c-shadow: #9b9d9f;
$c-slider-btn: #999;
$c-newsinfo: #888;
$c-bread: #777;
$c-ldark: #474747;
$c-form: #333;
$c-dark: #191919;
$c-overlay: #1d1415;
$c-default: #000;


$c-corp: #f00;
$c-scarlet: #fd1600;
$c-link: #0270c9;
$c-yell: #ffc832;
$c-sunny: #ffc600;
$c-yell-act: #ffba00;
$c-lyell: #fffcdd;
$c-success: #63a300;
$c-positive: #0a0;

$cLink: #0073cd;

$c-fb: #34508b;
$c-yt: #cd201f;
$c-ig: #d04269;
$c-twit: #55acee;
$c-gplus: #dc4e41;
$c-pint: #bd081c;
$c-viber: #8f5db7;
$c-tg: #0088cc;

$c-discount: #fd1600;
$c-hit: #fe8200;
$c-new: #828900;
$c-sale: #fb7f7c;
$c-promo: #fd1600;
$c-exclusive: #ffc600;

$c-preorder: #e5f1fa;

$shd-yell: rgba(255, 198, 0, 0.2);

// Cart product status
$c-discount: #f00;
$c-short: #fe8200;
$c-outofstock: #777;
$c-outofstock-btn: #fe8200;
$c-outofstock-light: #ff8221;
$cart-colors: (
  discount: $c-discount,
  short: $c-short,
  outofstock: $c-outofstock
);

// Social networks colors
$social-colors: (
  fb: $c-fb,
  yt: $c-yt,
  ig: $c-ig,
  twit: $c-twit,
  gplus: $c-gplus,
  pint: $c-pint,
  viber: $c-viber,
  tg: $c-tg
);
// Input Icons
$icons: (
  name: $fa-var-user-circle-o,
  email: $fa-var-envelope-o
);


// Order status icons colors
$c-new: #ffc732;
$c-in-process: #0270c9;
$c-confirm: #ff6600;
$c-done: #6ab528;
$c-payed: #219c00;

$c-denied: #ff0010;
$c-cancel: #777;

$c-ready-to-receive: #cfc52f;
$c-shipped: #4ecffd;
$c-packaging: #9438fb;
$c-waiting: #1079cf;

$c-credit-confirm: #005c44;
$c-bank: #ff711e;

// Order labels colors
$c-with-services: #15b600;

// Service
$c-service: #ff8200;
$c-service-info: #2d9cdb;
$c-service-secondary: #f99b3a;
$c-service-attention: #e44040;