body {
    background-color: $c-light;
    color: $c-default;
    font-family: $font-reg;
    font-size: $font-size;
    height: auto;
    margin: 0;
    max-width: 100%;
    overflow-x: hidden;
    padding: 0;
    position: relative;
    width: 100%;

    // Sticky footer
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    &.__body-fixed {
        height: 100%;
        overflow: hidden;
    }

    .page-container {
        position: relative;
    }
}

header {
  position: fixed;
  top: 0;
  transition: top $time ease-in-out;
  z-index: 150;

  &.__menu-hidden {
    top: -80px;
  }
}

footer {
  background-color: $c-corp;
  margin-top: auto;
  position: relative;
}

a,
a:hover,
a:focus,
a:active {
  color: $c-default;
}

button:focus {
  outline: 0;
}
