// Bootstrap mixins
@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
    $n: index($breakpoint-names, $name);
    @return if($n != null and $n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
    $min: map-get($breakpoints, $name);
    @return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
    $next: breakpoint-next($name, $breakpoints);
    @return if($next, breakpoint-min($next, $breakpoints) - .02px, null);
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
    $max: breakpoint-max($name, $breakpoints);
    @if $max {
        @media (max-width: $max) {
            @content;
        }
    } @else {
        @content;
    }
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    @if $min {
        @media (min-width: $min) {
            @content;
        }
    } @else {
        @content;
    }
}

// Main grid mixins
@mixin sm() {
    @include media-breakpoint-up(sm) {
        @content;
    }
}

@mixin md() {
    @include media-breakpoint-up(md) {
        @content;
    }
}

@mixin lg() {
    @include media-breakpoint-up(lg) {
        @content;
    }
}

@mixin xl() {
    @include media-breakpoint-up(xl) {
        @content;
    }
}


// Deprecated or rarely used grid mixins
@mixin desktop() {
    @include media-breakpoint-up(md) {
        @content;
    }
}

@mixin tab() {
    @include media-breakpoint-up(sm) {
        @content;
	}
}

@mixin ipad() {
    @include media-breakpoint-up(xs) {
        @content;
	}
}

@mixin phab() {
    @media all and (max-width: 599px) {
        @content;
	}
}

@mixin phone() {
    @media all and (max-width: 425px) {
        @content;
	}
}

@mixin iph() {
    @media all and (max-width: 375px) {
        @content;
	}
}

@mixin media($size) {
    @media all and (max-width: $size + px) {
        @content;
    }
}

@mixin media-min($size) {
    @media all and (min-width: $size + px) {
        @content;
    }
}

@mixin media-max($size) {
    @media all and (max-width: $size + px) {
        @content;
    }
}

@mixin hmedia($size) {
    @media all and (max-height: $size + px) {
        @content;
    }
}

@mixin full-width-block($color) {
    background-color: $color;
    margin-left: -10px;
    width: calc(100% + 20px);

    @include md {
        margin-left: 0;
        width: 100%;
    }
}
