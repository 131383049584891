.ReactModal {
  &__Body--open {
    overflow: hidden;
  }

  &__Overlay {

    &--after-open {
      transition: all 600ms;
      background-color: rgba(0, 0, 0, 0.75);

      .modal-content {
        animation-duration: 600ms;
        animation-fill-mode: both;
        animation-name: fadeInDown;
      }

      &.__side.__left {
        .modal-content {
          animation-name: fadeInLeft;
        }
      }

      &.__side.__right {
        .modal-content {
          animation-name: fadeInRight;
        }
      }
    }

    &--before-close {
      transition: all 600ms;
      background-color: rgba(0, 0, 0, 0.75);

      .modal-content {
        animation-duration: 600ms;
        animation-fill-mode: both;
        animation-name: fadeOutUp;
      }

      &.__side.__left {
        .modal-content {
          animation-name: fadeOutLeft;
        }
      }

      &.__side.__right {
        .modal-content {
          animation-name: fadeOutRight;
        }
      }
    }
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -30%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, -30%, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-30%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(-30%, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(-30%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(-30%, 0, 0);
  }
}
