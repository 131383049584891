.header {
    color: $c-default;
    background-color: $c-lgrey;

    &.__menu-open {
        background-color: $c-grey;
    }

    &-nav {
        align-items: center;
        display: flex;
        justify-content: space-between;
        height: 80px;
        padding: 0 10px;

        @include lg {
            position: relative;
        }

        @include xl {
            padding: 0 15px;
        }

        i {
            margin-left: 8px;
        }

        .logo {
            @include lg {
                display: none;
            }

            svg {
                height: 35px;
                width: 177px;

                @include media-breakpoint-down(xs) {
                    height: 25px;
                    width: 126px;
                }
            }
        }

        &-menu {
            align-items: center;
            justify-content: space-between;
            flex: 1;
            flex-direction: column;

            @include media-max(1023) {
                background-color: $c-grey;
                display: block;
                height: auto;
                left: 0;
                max-height: calc(100% - 80px);
                opacity: 0;
                overflow-y: scroll;
                padding: 0 10px 30px 10px;
                position: fixed;
                right: 0;
                text-align: left;
                top: 80px;
                transform: translate(0, -125%);
                transition: all $time*1.5 linear;
                width: 100%;
                z-index: -1;

            }

            @include lg {
                display: flex;
                flex-direction: row;
            }

            ul {
                @include lg {
                    display: flex;
                }
            }

            &.__open {
                opacity: 1;
                transform: translate(0, 0%);
            }

            .mobile-lang {
                display: flex;
                margin-top: 30px;

                @include lg {
                    display: none;
                }

                li {
                    margin-right: 20px;

                    a {
                        border-radius: 2px;
                        border: 1px solid $c-dgrey;
                        color: $c-default;
                        display: inline-block;
                        font-size: 16px;
                        line-height: 24px;
                        padding: 6px 22px;

                        &:hover {
                            color: $c-default;
                        }

                        &.__current {
                            border-color: $c-corp;
                        }
                    }
                }
            }


            .mobile-lang li a {
                border-color: $c-dgrey;
                color: $c-default;

                &:hover {
                    color: $c-default;
                }

                &.__current {
                    border-color: $c-corp;
                }
            }
        }

        .menu {
            &-item,
            &-sub {
                font-size: 1.3em;
                padding: 0 7px;

                @include sm {
                    padding: 0 10px;
                }

                @include lg {
                    font-size: 1.4em;
                    padding: 0 7px;
                }

                @include media-min(1150) {
                    padding: 0 10px;
                }

                @include xl {
                    padding: 0 15px;
                }

                a {
                    &.__selected {
                        color: $c-corp;

                        i {
                            transform-origin: 50% 50%;
                        }
                    }

                    &.__highlighted {
                        color: $c-corp;
                    }

                    &:hover {
                        fill: $c-corp;
                        color: $c-corp;
                        transition: color $time ease-in-out;
                    }
                }
            }

            .menu-item,
            .menu-sub {
                @include media-max(1023) {
                    display: block;
                    padding: 21px 0 10px;

                    cursor: pointer;

                    font-size: 1.6em;
                    line-height: 1em;

                    border-bottom: solid 1px rgba($c-default, 0.15);
                }

                @include media-breakpoint-down(xs) {
                    font-size: 1.4em;
                }
            }

            &-item {
                a {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .fa-angle-right {
                    @include lg {
                        display: none;
                    }
                }
            }

            &-sub {
                cursor: pointer;
                position: relative;

                > a {
                    @include media-max(1023) {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }

                ul {
                    display: none;
                    padding-top: 10px;

                    @include lg {
                        position: absolute;
                        top: 35px;
                        margin-left: -16px;
                        padding-top: 0;
                        text-align: left;
                        background-color: $c-lgrey;
                        box-shadow: 0 5px 18px 2px rgba($c-dark, 0.1);
                        z-index: 100;
                    }

                    a {
                        display: block;
                        height: 34px;
                        line-height: 34px;
                        padding: 0 16px;
                        transition: all $time ease-in-out;
                    }

                    li {
                        display: block;
                        white-space: nowrap;

                        + li {
                            margin-left: 0;
                        }

                        &:hover {
                            @include lg {
                                background-color: $c-light;
                            }
                        }
                    }
                }
            }
        }

        &-btn {
            display: none;

            @include lg {
                display: block;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &-icons {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin-left: auto;

            li {
                margin-right: 5px;

                @include sm {
                    margin-right: 50px;
                }

                @include lg {
                    margin-right: 0;
                }
            }

            .__icon {
                font-size: 18px;
                position: relative;

                @include sm {
                    font-size: 25px;
                }

                @include lg {
                    font-size: 16px;
                }

                .shop-icon-user {
                    transition: color $time ease-in-out;

                    &:hover {
                        color: $c-corp;
                    }
                }

                .shop-icon-search,
                .shop-icon-user,
                .shop-icon-cart-bag {
                    align-items: center;
                    display: flex;
                    height: 27px;
                }

                &.open-login-modal:hover {

                    .shop-icon-user {
                        color: $c-corp;
                        transition: color $time ease-in-out;
                    }
                }

                .menu-item-count {
                    background: $c-corp;
                    border-radius: 50%;
                    color: $c-light;
                    display: block;
                    position: absolute;
                    right: -10px;
                    text-align: center;
                    top: -8px;
                    font-size: 10px;
                    height: 18px;
                    line-height: 18px;
                    width: 18px;

                    @include sm {
                        font-size: 15px;
                        height: 22px;
                        line-height: 22px;
                        width: 22px;
                    }

                    @include lg {
                        font-size: 10px;
                        height: 16px;
                        line-height: 16px;
                        right: -10px;
                        top: -5px;
                        width: 16px;
                    }
                }
            }

            .open-login-modal {
                position: relative;

                &.__loggedin {
                    @include media-breakpoint-down(xs) {
                        margin-right: 10px;
                    }

                    .username {
                        @include media-breakpoint-down(xs) {
                            background: $c-dgrey;
                            border-radius: 50%;
                            display: block;
                            font-size: 16px;
                            height: 27px;
                            line-height: 27px;
                            text-align: center;
                            text-transform: uppercase;
                            width: 27px;
                        }

                        @include sm {
                            bottom: -12px;
                            font-size: 12px;
                            left: 50%;
                            position: absolute;
                            transform: translateX(-50%);
                            white-space: nowrap;

                            @include lg {
                                bottom: -10px;
                            }

                            &::after {
                                content: ' +';
                            }
                        }
                    }

                    .shop-icon-user {
                        @include media-breakpoint-down(xs) {
                            display: none;
                        }
                    }

                    i {
                        display: inline-block;
                        position: absolute;
                        right: -15px;
                        top: 50%;
                        transform: translateY(-50%);

                        @include sm {
                            display: none;
                        }
                    }
                }

                i {
                    display: none;
                }
            }
        }

        .burger {
            position: relative;
            height: 30px;
            width: 35px;
            padding: 0;
            background-color: transparent;
            border: 0;
            z-index: 99;

            @include media-breakpoint-down(xs) {
                height: 25px;
                width: 25px;
                margin-left: 10px;
            }

            @include lg {
                display: none;
            }

            &.open span {
                &:nth-child(1) {
                    top: 18px;
                    width: 0;
                    left: 50%;
                }

                &:nth-child(2) {
                    transform: rotate(45deg);
                }

                &:nth-child(3) {
                    transform: rotate(-45deg);
                }

                &:nth-child(4) {
                    top: 18px;
                    width: 0;
                    left: 50%;
                }
            }

            span {
                background: $c-default;
                display: block;
                height: 4px;
                left: 0;
                opacity: 1;
                position: absolute;
                transform: rotate(0deg);
                transition: $time ease-in-out;
                width: 100%;

                @include media-breakpoint-down(xs) {
                    height: 3px;
                }

                &:nth-child(1) {
                    top: 3px;
                }

                &:nth-child(2),
                &:nth-child(3) {
                    top: 12px;

                    @include sm {
                        top: 13px;
                    }
                }

                &:nth-child(4) {
                    top: 24px;

                    @include media-breakpoint-down(xs) {
                        top: 20px;
                    }
                }
            }
        }

        .user-menu {
            background: $c-lgrey;
            box-shadow: 0 5px 18px 2px rgba($c-dark, 0.1);
            cursor: default;
            display: none;
            right: 0;
            padding: 20px 6px;
            position: absolute;
            position: fixed;
            height: auto;
            max-height: calc(100% - 80px);
            overflow-y: auto;
            top: 80px;
            width: 320px;
            z-index: 1000;

            &-container {
                display: flex;
                flex-wrap: wrap;
                position: relative;

                &::after {
                    content: '';
                    background: $c-lgrey;
                    display: block;
                    height: 1px;
                    left: 0;
                    bottom: 0;
                    position: absolute;
                    width: 100%;
                }
            }

            &-item {
                align-items: center;
                border-bottom: 1px dashed rgba($c-default, 0.1);
                cursor: pointer;
                display: flex;
                min-height: 106px;
                text-align: center;
                flex: 0 0 33.33333%;
                transition: $time ease-in-out;

                &:hover {
                    .user-menu-item-icon,
                    .user-menu-item-txt {
                        color: $c-corp;
                    }
                }

                &.__active {
                    cursor: default;

                    .user-menu-item-icon {
                        color: $c-corp;
                    }

                    .user-menu-item-txt {
                        font-family: $font-bold;
                    }

                    a {
                        cursor: default;
                    }

                    &:hover {
                        background: transparent;

                        .user-menu-item-txt {
                            color: $c-default;
                        }
                    }
                }

                &.__inactive {
                    cursor: default;

                    &:hover {
                        .user-menu-item-icon {
                            color: $c-bread;
                        }

                        .user-menu-item-txt {
                            color: $c-default;
                        }
                    }

                    a {
                        cursor: default;
                        opacity: 0.26;
                    }
                }

                a {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 100%;
                    line-height: 1;
                    margin: 0 auto;
                    padding: 0;
                }

                &-icon {
                    align-items: center;
                    color: $c-bread;
                    display: block;
                    height: 30px;
                    margin: 0 auto 5px;
                    position: relative;
                    width: 26px;

                    i {
                        display: inline-block;
                        font-size: 22px;
                        line-height: 22px;
                        margin-left: 0;
                        position: relative;
                        z-index: 1;
                    }
                }

                &-count {
                    border-radius: 50%;
                    color: $c-light;
                    display: block;
                    font-size: 10px;
                    line-height: 20px;
                    background: $c-corp;
                    height: 20px;
                    width: 20px;
                    position: absolute;
                    right: -13px;
                    top: -7px;
                    z-index: 2;
                }

                &-txt {
                    display: inline-block;
                    font-size: 12px;
                    line-height: 16px;
                    position: relative;
                    text-align: center;
                    width: 100%;
                    z-index: 1;
                }
            }
        }

        &-lang {
            display: none;

            @include lg {
                display: block;
            }
        }
    }
}
