.txtLink {
    font-family: $font-reg;
    font-size: 16px;
    line-height: 24px;
    color: $cLink;
    cursor: pointer;
    transition: $time/2 linear;
    position: relative;
    text-decoration: none;

    &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 12px;
        bottom: 0;
        height: 1px;
        background: $cLink;
        transition: $time/2 linear;
        transform: scalex(0);
        transform-origin: left center;
    }

    @include hover {
        color: $cLink;
        text-decoration: none;

        &:after {
            transform: scalex(1);
        }
    }
}

.txtLinkMore {
    @extend .txtLink;
    padding-right: 12px;
    position: relative;

    &:before {
        content: $fa-var-angle-right;
        font-family: FontAwesome;
        display: block;
        position: absolute;
        right: 0;
        bottom: 3px;
        font-size: 14px;
        line-height: 1;
    }
}

.linkAnimated {
    color: $c-link;
    cursor: pointer;
    font-family: $font-reg;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    text-decoration: none;
    transition: $time / 2 linear;
    white-space: nowrap;

    &::after {
        background-color: $c-link;
        bottom: 0;
        content: '';
        display: block;
        height: 1px;
        left: 0;
        position: absolute;
        right: 0;
        transform: scaleX(0);
        transform-origin: left center;
        transition: $time / 2 linear;
    }

    @include hover {
        color: $c-link;
        text-decoration: none;

        &::after {
            transform: scaleX(1);
        }
    }
}

.linkMoreAnimated {
    @extend .linkAnimated;
    padding-right: 12px;

    &::after {
        right: 12px;
    }

    &::before {
        bottom: 3px;
        content: $fa-var-angle-right;
        display: block;
        font-family: FontAwesome;
        font-size: 14px;
        line-height: 1;
        position: absolute;
        right: 0;
    }
}
