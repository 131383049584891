.login {
    margin: 0 auto;
    max-width: 450px;

    &-container {
        @include lg {
            margin-top: 50px;
        }

        @include xl {
            margin-top: 100px;
        }
    }

    .login-tabs {
        background: $c-light;
        color: $c-default;

        &-item {
            font-size: 16px;
            text-transform: none;
            width: 50%;
            min-width: 50%;

            @include md {
                font-size: 18px;
            }

            &.__active {
                font-family: $font-bold;
            }
        }
    }

    &-body {
        padding-top: 15px;
    }

    &-warning {
        border: 1px solid $c-sunny;
        display: flex;
        margin-bottom: 16px;
        
        &-icon {
            background: $c-sunny;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 12px;
            width: 100px;
        }

        &-message {
            font-size: 14px;
            line-height: 1.4;
            padding: 10px;

            @include md {
                padding: 20px;
            }
        }
    }

    .forgot-password-form {
        margin-top: 30px;
    }

    .button-link {
        font-size: 16px;
        color: $c-link;
        cursor: pointer;
    }

    &-form {
        &-title {
            font-size: 16px;
            margin-bottom: 10px;
            text-align: center;
        }
    }

    input[type="submit"] {
        background: $c-corp;
        border: none;
        border-radius: 2px;
        color: $c-light;
        font-size: 16px;
        height: 50px;
        width: 100%;
        transition: all $time;

        &:hover,
        &:active {
            background: darken($c-corp, 10%);
        }
    }

    .form-input-container.__icon.__password {

        input {
            padding-right: 30px;
        }

        .show-password {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: $c-none;
            border: none;
            font-size: 16px;
        }
    }

    .form-item-error .__error {
        @include media-breakpoint-down(sm) {
            padding-bottom: 0;
        }
    }

    &-social {
        background: $c-lgrey2;
        margin: 15px auto 30px;
        padding: 20px 10px 30px;

        p {
            font-size: 14px;
            margin-bottom: 15px;
        }

        &-title {
            margin-bottom: 30px;
            text-align: center;
        }

        &-buttons {
            align-items: center;
            display: flex;
            justify-content: center;

            button {
                align-items: center;
                background: $c-none;
                border: 1px solid;
                border-radius: 2px;
                display: flex;
                color: $c-light;
                font-size: 19px;
                height: 40px;
                margin-right: 20px;
                padding: 0;
                transition: all $time;
                width: 140px;

                &:last-child {
                    margin-right: 0;
                }

                &.__facebook {
                    border-color: $c-fb;

                    &:hover,
                    &:active {
                        background: $c-fb;

                        .text {
                            color: $c-light;
                        }
                    }

                    .icon {
                        background: $c-fb;
                    }
                }

                &.__google {
                    border-color: $c-gplus;

                    &:hover,
                    &:active {
                        background: $c-gplus;

                        .text {
                            color: $c-light;
                        }
                    }

                    .icon {
                        background: $c-gplus;
                    }
                }

                .icon {
                    flex: 0 0 40px;
                    height: 38px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .text {
                    color: $c-default;
                    font-size: 14px;
                    line-height: 1.5;
                    flex-grow: 1;
                }
            }
        }
    }
}

.modal-login {
    .modal-header {
        margin-bottom: 0;
        position: absolute;
        right: 0;
        padding: 16px 16px 0 0;
    }

    .modal-dialog {
        box-sizing: content-box;
        max-width: 445px;
    }

    .modal-close {
        width: 16px;

        .close::before {
            font-size: 16px;
        }
    }

    .modal-body {
        padding: 45px 15px 45px;
    }
}

.social-icon {
    align-items: center;
    border-radius: 50%;
    color: $c-light;
    display: inline-flex;
    font-size: 19px;
    justify-content: center;
    height: 40px;
    width: 40px;

    &.__facebook {
        background: $c-fb;
    }

    &.__google {
        background: $c-gplus;
    }
}

.registration-corp {
    margin: 50px 0 100px;

    @include lg {
        margin-bottom: 270px;
    }

    .container h2.title-lg {
        margin-bottom: 25px;
    }

    &-form {
        .form-item-title-name::after {
            content: ' *';
            color: $c-corp;
            font-size: 16px;
            position: relative;
            top: -3px;
        }

        .form-input-container.__password {
            input {
                padding-right: 30px;
            }

            .show-password {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                background-color: $c-none;
                border: none;
                font-size: 16px;
            }
        }

        input[type="submit"] {
            background: $c-corp;
            border: none;
            border-radius: 2px;
            color: $c-light;
            font-size: 16px;
            height: 50px;
            margin-top: 25px;
            width: 100%;
            transition: all $time;

            &:hover,
            &:active {
                background: darken($c-corp, 10%);
            }
        }
    }

    &-success {
        padding: 60px 0;
        text-align: center;

        @include md {
            padding: 200px 0;
        }

        h3 {
            font-size: 1.4rem;
            margin-bottom: 30px;
        }

        a {
            border-color: $c-corp;
            color: $c-corp;
            width: 200px;
        }
    }
}