//@import url("//hello.myfonts.net/count/34b07b");

@font-face {
  font-family: 'NeueHaasUnicaW1G-Regular';
  src: url(#{$assets-url}/fonts/custom/34B07B_2_0.eot);
  src: url(#{$assets-url}/fonts/custom/34B07B_2_0.eot?#iefix)
  format('embedded-opentype'), url(#{$assets-url}/fonts/custom/34B07B_2_0.woff2)
  format('woff2'), url(#{$assets-url}fonts/custom/34B07B_2_0.woff)
  format('woff'), url(#{$assets-url}/fonts/custom/34B07B_2_0.ttf)
  format('truetype');
}

@font-face {
  font-family: 'NeueHaasUnicaW1G-Bold';
  src: url(#{$assets-url}/fonts/custom/34B07B_0_0.eot);
  src: url(#{$assets-url}/fonts/custom/34B07B_0_0.eot?#iefix)
  format('embedded-opentype'), url(#{$assets-url}/fonts/custom/34B07B_0_0.woff2)
  format('woff2'), url(#{$assets-url}/fonts/custom/34B07B_0_0.woff)
  format('woff'), url(#{$assets-url}/fonts/custom/34B07B_0_0.ttf)
  format('truetype');
}

@font-face {
  font-family: 'NeueHaasUnicaW1G-Light';
  src: url(#{$assets-url}/fonts/custom/34B07B_1_0.eot);
  src: url(#{$assets-url}/fonts/custom/34B07B_1_0.eot?#iefix)
  format('embedded-opentype'), url(#{$assets-url}/fonts/custom/34B07B_1_0.woff2)
  format('woff2'), url(#{$assets-url}/fonts/custom/34B07B_1_0.woff)
  format('woff'), url(#{$assets-url}/fonts/custom/34B07B_1_0.ttf)
  format('truetype');
}

/* Icons */
@font-face {
  font-family: 'LenovoShop';
  src: url(#{$assets-url}/fonts/icons/LenovoShop.eot);
  src: url(#{$assets-url}/fonts/icons/LenovoShop.eot?#iefix) format('embedded-opentype'),
  url(#{$assets-url}/fonts/icons/LenovoShop.woff) format('woff'),
  url(#{$assets-url}/fonts/icons/LenovoShop.ttf) format('truetype'),
  url(#{$assets-url}/fonts/icons/LenovoShop.svg#LenovoShop) format('svg');
  font-weight: normal;
  font-style: normal;
}
[class*='shop-']:before{
  display: inline-block;
  font-family: 'LenovoShop';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}
.shop-icon-logo-mobile:before{content:'\0041';}
.shop-icon-search:before{content:'\0042';}
.shop-icon-user:before{content:'\0043';}
.shop-icon-cart-bag:before{content:'\0044';}
.shop-icon-cart-rocket:before{content:'\0045';}
.shop-icon-cart-check:before{content:'\0046';}
.shop-icon-button-shopping:before{content:'\0047';}
.shop-icon-angle-left:before{content:'\0048';}
.shop-icon-angle-right:before{content:'\0049';}
.shop-icon-arrow-left:before{content:'\004a';}
.shop-icon-arrow-right:before{content:'\004b';}
.shop-icon-thick-arrow-right:before{content:'\004c';}
.shop-icon-check:before{content:'\004d';}
.shop-icon-plus:before{content:'\004e';}
.shop-icon-close-thick:before{content:'\004f';}
.shop-icon-close-thin:before{content:'\0050';}
.shop-icon-delete:before{content:'\0051';}
.shop-icon-delete-active:before{content:'\0052';}
.shop-icon-info:before{content:'\0053';}
.shop-icon-info-active:before{content:'\0054';}
.shop-icon-bell:before{content:'\0055';}
.shop-icon-category-list:before{content:'\0056';}
.shop-icon-category-tiles:before{content:'\0057';}
.shop-icon-compare:before{content:'\0058';}
.shop-icon-favorites:before{content:'\0059';}
.shop-icon-favorites-active:before{content:'\005a';}
.shop-icon-pdf:before{content:'\0061';}
.shop-icon-pdf-active:before{content:'\0062';}
.shop-icon-star:before{content:'\0063';}
.shop-icon-faq:before{content:'\0064';}
.shop-icon-edit:before{content:'\0065';}
.shop-icon-like:before{content:'\0066';}
.shop-icon-dislike:before{content:'\0067';}
.shop-icon-details:before{content:'\0068';}
.shop-icon-customers:before{content:'\0069';}
.shop-icon-comment:before{content:'\006a';}
.shop-icon-accessories:before{content:'\006b';}
.shop-icon-gift:before{content:'\006c';}
.shop-icon-delivery-info:before{content:'\006d';}
.shop-icon-delivery-track:before{content:'\006e';}
.shop-icon-delivery-normal:before{content:'\006f';}
.shop-icon-delivery-fast:before{content:'\0070';}
.shop-icon-terms-courier:before{content:'\0071';}
.shop-icon-terms-selfdelivery:before{content:'\0072';}
.shop-icon-terms-payment:before{content:'\0073';}
.shop-icon-wallet-simple:before{content:'\0074';}
.shop-icon-location:before{content:'\0075';}
.shop-icon-time:before{content:'\0076';}
.shop-icon-weight:before{content:'\0077';}
.shop-icon-tech-specs:before{content:'\0078';}
.shop-icon-tech-display:before{content:'\0079';}
.shop-icon-tech-configuration:before{content:'\007a';}
.shop-icon-tech-network:before{content:'\0030';}
.shop-icon-tech-size:before{content:'\0031';}
.shop-icon-tech-battery:before{content:'\0032';}
.shop-icon-tech-setup:before{content:'\0033';}
.shop-icon-message:before{content:'\0034';}
.shop-icon-viber:before{content:'\0035';}
.shop-icon-email:before{content:'\0036';}
.shop-icon-360-view:before{content:'\0037';}
.shop-icon-attach:before{content:'\0038';}
.shop-icon-credit:before{content:'\0039';}
.shop-icon-review:before{content:'\0021';}
.shop-icon-video:before{content:'\0022';}
.shop-icon-play:before{content:'\0023';}
.shop-icon-play-active:before{content:'\0024';}
.shop-icon-fail:before{content:'\0025';}
.shop-icon-reply:before{content:'\0026';}
.shop-icon-np-marker:before{content:'\0027';}
.shop-icon-scale:before{content:'\0028';}
.shop-icon-mobile:before{content:'\0029';}
.shop-sort:before{content:'\002a';}
.shop-filter:before{content:'\002b';}
.shop-icon-printer:before{content:'\002c';}
.shop-icon-filter-o:before{content:'\002d';}


/* Footer Icons */
@font-face {
  font-family: 'FooterIcons';
  src: url(#{$assets-url}/fonts/footer-icons/FooterIcons.eot);
  src: url(#{$assets-url}/fonts/footer-icons/FooterIcons.eot?#iefix) format('embedded-opentype'),
  url(#{$assets-url}/fonts/footer-icons/FooterIcons.woff) format('woff'),
  url(#{$assets-url}/fonts/footer-icons/FooterIcons.ttf) format('truetype'),
  url(#{$assets-url}/fonts/footer-icons/FooterIcons.svg#FooterIcons) format('svg');
  font-weight: normal;
  font-style: normal;
}
[class*='footer-icon-']:before{
  display: inline-block;
  font-family: 'FooterIcons';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}
.footer-icon-blog:before{content:'\0041';}
.footer-icon-accessories:before{content:'\0042';}
.footer-icon-buyers:before{content:'\0043';}
.footer-icon-laptops:before{content:'\0044';}
.footer-icon-monitors:before{content:'\0045';}
.footer-icon-monoblocks:before{content:'\0046';}
.footer-icon-pc:before{content:'\0047';}
.footer-icon-phones:before{content:'\0048';}
.footer-icon-portal:before{content:'\0049';}
.footer-icon-servers:before{content:'\004a';}
.footer-icon-support:before{content:'\004b';}
.footer-icon-tablets:before{content:'\004c';}
.footer-icon-workstations:before{content:'\004d';}
.footer-icon-news:before{content:'\004e';}
.footer-icon-bag:before{content:'\004f';}

/* Lenovo Icomoon icons*/
@font-face {
  font-family: 'LenovoIcomoon';
  src:  url(#{$assets-url}/fonts/lenovo-icomoon/LenovoIcomoon.eot?a5pzr6);
  src:  url(#{$assets-url}/fonts/lenovo-icomoon/LenovoIcomoon.eot?a5pzr6#iefix) format('embedded-opentype'),
  url(#{$assets-url}/fonts/lenovo-icomoon/LenovoIcomoon.ttf?a5pzr6) format('truetype'),
  url(#{$assets-url}/fonts/lenovo-icomoon/LenovoIcomoon.woff?a5pzr6) format('woff'),
  url(#{$assets-url}/fonts/lenovo-icomoon/LenovoIcomoon.svg?a5pzr6#LenovoIcomoon) format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="lenovo-icon-"], [class*=" lenovo-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'LenovoIcomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.lenovo-icon-3d-view:before {content: "\e902";}
.lenovo-icon-delivery:before {content: "\e900";}
.lenovo-icon-delivery-alt:before {content: "\e901";}
.lenovo-icon-photo:before {content: "\e903";}
.lenovo-icon-play:before {content: "\e904";}
.lenovo-icon-play-active:before {content: "\e905";}
.lenovo-icon-printer:before {content: "\e916";}
.lenovo-icon-tech-audio:before {content: "\e906";}
.lenovo-icon-tech-battery:before {content: "\e907";}
.lenovo-icon-tech-configuration:before {content: "\e908";}
.lenovo-icon-tech-display:before {content: "\e909";}
.lenovo-icon-tech-harddrive:before {content: "\e90a";}
.lenovo-icon-tech-network:before {content: "\e90b";}
.lenovo-icon-tech-os:before {content: "\e90c";}
.lenovo-icon-tech-ports:before {content: "\e90d";}
.lenovo-icon-tech-ports-alt:before {content: "\e918";}
.lenovo-icon-tech-processor:before {content: "\e90e";}
.lenovo-icon-tech-ram:before {content: "\e915";}
.lenovo-icon-tech-security:before {content: "\e90f";}
.lenovo-icon-tech-setup:before {content: "\e910";}
.lenovo-icon-tech-size:before {content: "\e911";}
.lenovo-icon-tech-specs:before {content: "\e912";}
.lenovo-icon-tech-video:before {content: "\e913";}
.lenovo-icon-tech-webcam:before {content: "\e914";}
.lenovo-icon-tech-weight:before {content: "\e917";}
.lenovo-icon-tech-wifi:before {content: "\e919";}
.lenovo-icon-map_marker:before {content: "\e91b";}
.lenovo-icon-check-in-circle:before {content: "\e91a";}
.lenovo-icon-share:before {content: "\e91c";}
.lenovo-icon-warranty:before {content: "\e91d";}
.lenovo-icon-type-c:before {content: "\e91e";}
.lenovo-icon-share-link:before {content: "\e91f";}
.lenovo-icon-keyboard:before {content: "\e920";}
.lenovo-icon-change:before {content: "\e921";}
.lenovo-icon-logout:before {content: "\e922";}
.lenovo-icon-delete:before {content: "\e923";}
.lenovo-icon-basket:before {content: "\e924";}
.lenovo-icon-bell:before {content: "\e925";}
.lenovo-icon-compare:before {content: "\e926";}
.lenovo-icon-device:before {content: "\e927";}
.lenovo-icon-favorites-active:before {content: "\e928";}
.lenovo-icon-favorites:before {content: "\e929";}
.lenovo-icon-gift:before {content: "\e92a";}
.lenovo-icon-info:before {content: "\e92b";}
.lenovo-icon-message:before {content: "\e92c";}
.lenovo-icon-pencil:before {content: "\e92d";}
.lenovo-icon-user:before {content: "\e92e";}
.lenovo-icon-wallet:before {content: "\e92f";}
.lenovo-icon-people:before {content: "\e930";}
.lenovo-icon-filter:before {content: "\e931";}
.lenovo-icon-filter-reset:before {content: "\e932";}
.lenovo-icon-warning:before {content: "\e933";}
.lenovo-icon-b2b:before {content: "\e934";}
.lenovo-icon-load:before {content: "\e935";}

// Lenovo Legion Official Font
@font-face {
  font-family: 'Gotham';
  font-weight: 300;
  src: url(#{$assets-url}/fonts/gotham/Gotham-Light.otf);
}

@font-face {
  font-family: 'Gotham';
  font-weight: normal;
  src: url(#{$assets-url}/fonts/gotham/Gotham-Book.otf);
}

@font-face {
  font-family: 'Gotham';
  font-weight: 500;
  src: url(#{$assets-url}/fonts/gotham/Gotham-Medium.otf);
}

@font-face {
  font-family: 'Gotham';
  font-weight: bold;
  src: url(#{$assets-url}/fonts/gotham/Gotham-Bold.otf);
}

@font-face {
  font-family: 'Gotham';
  font-weight: 900;
  src: url(#{$assets-url}/fonts/gotham/Gotham-Black.otf);
}