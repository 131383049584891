.form {
    &-container {
        margin-bottom: 35px;

        .page-title {
            font-size: 30px;
            line-height: 1.25;
            margin-bottom: 35px;
            margin-left: 0;

            @include sm {
                font-size: 35px;
            }
        }
    }

    &-fieldset {
        margin-bottom: 10px;

        .text-title {
            display: flex;
            justify-content: space-between;
            font-size: 22px;
            margin-bottom: 15px;
        }
    }

    &-item {
        margin-top: 4px;
        margin-bottom: 4px;

        &-title {
            align-items: center;
            display: flex;
            font-size: 12px;
            height: 30px;
            justify-content: space-between;
            line-height: 1.25;

            &-name {
                span  {
                    color: $c-bread;
                    white-space: nowrap;

                    .error & {
                        color: $c-corp;
                    }
                }

                sup {
                    color: $c-corp;
                }

                &.__icon {
                    font-size: 16px;
                    line-height: 1.25;
                    padding-left: 33px;
                    position: relative;

                    &::before {
                        color: $c-default !important;
                        font-family: $font-lenovo-shop;
                        font-size: 21px;
                        left: 0;
                        position: absolute;
                        top: 0;
                    }

                    &.__plus {
                        &::before {
                            content: '\0066';
                            top: -3px;
                        }
                    }

                    &.__minus {
                        &::before {
                            content: '\0067';
                            top: 3px;
                        }
                    }

                    &.__mail {
                        &::before {
                            content: '\0036';
                            top: -3px;
                        }
                    }

                    &.__viber {
                        &::before {
                            content: '\0035';
                            top: -3px;
                        }
                    }

                    &.__sms {
                        &::before {
                            content: '\0034';
                            top: -3px;
                        }
                    }
                }
            }

            &-info {
                color: $c-bread;
                white-space: nowrap;
            }

            &-accordion {
                align-items: flex-start;
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                font-size: 18px;
                min-height: 30px;

                span {
                    padding-right: 8px;
                }

                i {
                    flex-shrink: 0;
                    font-size: 24px;
                    line-height: 30px;
                    transition: $time;

                    &.__active {
                        color: $c-corp;
                    }

                    &.__open {
                        transform: scaleY(-1);
                    }
                }
            }

            .error & {
                color: $c-corp;
            }
        }

        &-error {
            min-height: 20px;
            position: relative;

            .__error {
                color: $c-corp;
                display: block;
                font-size: 11px;
                line-height: 1.25;
                padding: 5px 0 15px;
            }
        }

        &.__simple {
            margin-bottom: 0;
        }

        &-text {
            p {
                color: $c-bread;
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 16px;
            }

            a {
                color: $c-link;
                display: block;
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 5px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        > a {
            display: inline-block;
            font-size: 16px;
            line-height: 24px;
            position: relative;
            transition: $time linear;
            vertical-align: middle;
        }
    }

    &-input-container {
        position: relative;
        padding-bottom: 4px;
        padding-top: 4px;

        &-accordion {
            position: relative;
            width: 100%;
            display: none;
            padding-bottom: 4px;
            padding-top: 4px;

            &.__active {
                display: block;
            }
        }

        &.__icon {
            &::before {
                top: 50%;
                color: $c-bread;
                content: '';
                display: none;
                font-family: $font-icon;
                font-size: 17px;
                left: 5px;
                line-height: 1;
                pointer-events: none;
                position: absolute;
                transform: translateY(-50%);
            }

            @each $icon-name, $icon-content in $icons {
                &.__#{$icon-name}::before {
                    content: $icon-content;
                    display: block;
                }
            }
        }

        &.__starred {
            &::after {
                background-color: $c-light;
                color: $c-corp;
                content: '*';
                font-size: 16px;
                line-height: 42px;
                height: 36px;
                pointer-events: none;
                position: absolute;
                right: 0;
                text-align: right;
                top: 2px;
                width: 10px;
            }
        }

        .symbols {
            font-size: 16px;
            line-height: 24px;
            margin-top: 5px;

            @include lg {
                margin-top: 10px;
            }

            &.__full {
                color: $c-corp;
            }
        }
    }

    input[type='search'] {
        box-sizing: border-box;
    }

    &-input {
        background-color: $c-none;
        border: 0;
        border-bottom: solid 1px $c-dgrey;
        color: $c-default;
        font-size: 16px;
        height: 40px;
        line-height: 38px;
        outline: 0;
        padding-bottom: 1px;
        transition: $time ease-in-out;
        width: 100%;

        @include hover {
            border-bottom: solid 2px $c-dgrey;
            outline: 0;
            padding-bottom: 0;
        }

        .error & {
            border-bottom: solid 2px $c-corp;
            padding-bottom: 0;
        }

        .__icon & {
            padding-left: 25px;
        }
    }

    &-input-simple {
        background-color: $c-light;
        border: solid 1px $c-dgrey;
        color: $c-default;
        font-size: 16px;
        height: 40px;
        line-height: 38px;
        outline: 0;
        padding: 0 15px;
        width: 100%;

        .error & {
            border-color: $c-corp;
        }
    }

    &-textarea-simple {
        background-color: $c-light;
        border: solid 1px $c-dgrey;
        color: $c-default;
        font-size: 16px;
        height: 120px;
        outline: 0;
        padding: 8px 15px;
        resize: none;
        width: 100%;

        .error & {
            border-color: $c-corp;
        }

        &.__full {
            border-color: $c-corp;
        }
    }

    &-textarea-fluid {
        border: 1px solid $c-dgrey;
        font-family: $font-reg;
        font-size: 16px;
        line-height: 24px;
        min-height: 42px;
        outline: 0;
        padding: 8px 15px;
        transition: height $time linear;
        width: 100%;

        &.__mid {
            min-height: 66px;
        }

        &.__big {
            min-height: 114px;
        }

        &-container {
            font-size: 0;
            position: relative;
        }

        &-js {
            overflow: hidden;
            resize: none;
        }

        &-hiddendiv {
            display: none;
            left: 0;
            overflow-wrap: break-word;
            position: absolute;
            top: 0;
            white-space: pre-wrap;
            word-wrap: break-word;
        }

        .error & {
            border-color: $c-corp;
        }
    }

    &-submit {
        align-items: center;
        background-color: $c-corp;
        border: 0;
        border-radius: 2px;
        color: $c-light;
        display: flex;
        font-size: 18px;
        height: 50px;
        justify-content: center;
        line-height: 1;
        outline: 0;
        transition: $time ease-in-out;
        width: 100%;

        @include sm {
            padding: 0 30px;
            max-width: 100%;
            width: auto;
        }

        &:hover {
            box-shadow: 0 5px 18px 2px rgba($c-corp, .2);
        }
    }

    .modal-submit {
        @extend .form-submit;
        background-color: $c-exclusive;
        color: $c-default;
        font-size: 16px;

        &:hover {
            box-shadow: 0 5px 18px 2px rgba($c-exclusive, .2);
        }

        &-container {
            position: relative;

            &.__loading {
                input[type="submit"] {
                    cursor: not-allowed;
                    opacity: .4;
                }

                .bouncing-loader {
                    opacity: 1;
                    visibility: visible;
                }
            }

            .bouncing-loader {
                left: 0;
                max-height: 100%;
                opacity: 0;
                position: absolute;
                top: 0;
                visibility: hidden;
                width: 100%;
            }
        }
    }

    .modal-remind {
        background-color: $c-hit;
        width: 100%;

        &:hover {
            box-shadow: 0 5px 18px 2px rgba($c-hit, .2);
        }
    }
}

.form-select {
    position: relative;
    margin-top: 4px;
    margin-bottom: 4px;

    &-link {
        background-color: $c-light;
        border-bottom: solid 1px $c-dgrey;
        color: $c-bread;
        display: block;
        font-size: 16px;
        height: 40px;
        line-height: 1;
        overflow: hidden;
        padding-top: 12px;
        position: relative;
        transition: $time ease-in-out;
        white-space: nowrap;
        width: 100%;

        &:hover {
            border-bottom: solid 2px $c-dgrey;
        }

        &::before {
            background-color: $c-light;
            content: '';
            height: 38px;
            position: absolute;
            right: 0;
            top: 0;
            width: 15px;
        }

        &::after {
            color: $c-bread;
            content: $fa-var-angle-down;
            display: block;
            font-family: $font-icon;
            position: absolute;
            right: 0;
            top: 14px;
            transform-origin: 50% 50%;
            transition: transform $time ease-in-out;
        }

        &.-active {
            color: $c-default;
        }

        .error & {
            border-bottom: solid 2px $c-corp;
            padding-bottom: 0;
        }
    }

    &-right {
        margin-left: auto;
        // width may vary on different forms
        width: 195px;
    }

    &.__blue {
        .form-select-link {
            border: 0;
            color: $c-link;
            margin-left: auto;
            padding-right: 14px;
            text-align: right;

            &:hover {
                border: 0;
            }

            &::before {
                width: 13px;
            }

            &::after {
                color: $c-link;
                content: $fa-var-angle-right;
                font-size: 14px;
                line-height: 1;
                right: 2px;
                top: 15px;
            }

            span {
                @extend .linkAnimated;
                line-height: 1;
            }
        }

        &.-open {
            .form-select-link {
                border: 0;

                &::after {
                    transform: rotate(90deg);
                    transform-origin: center;
                }
            }
        }
    }

    &-content {
        background-color: $c-light;
        box-shadow: none;
        display: block;
        left: 0;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        padding: 10px 0;
        position: absolute;
        top: 40px;
        transition: opacity $time ease-in-out;
        visibility: hidden;
        width: 100%;
        z-index: 101;

        &::-webkit-scrollbar {
            height: 7px;
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background: $c-dgrey;
        }

        &::-webkit-scrollbar-track {
            background: $c-light;
        }

        & {
            scrollbar-face-color: $c-dgrey;
            scrollbar-track-color: $c-light;
        }
    }

    &.-open {
        .form-select-content {
            box-shadow: 0px 5px 18px 2px rgba($c-shadow, 0.2);
            max-height: 180px;
            opacity: 1;
            overflow-y: auto;
            visibility: visible;
        }

        .form-select-link {
            border-bottom: solid 2px $c-dgrey;

            &::after {
                transform: scaleY(-1);
            }
        }

        .error & {
            .form-select-link {
                border-bottom: solid 2px $c-corp;
                padding-bottom: 0;
            }
        }
    }

    &-item {
        color: $c-bread;
        display: block;
        font-size: 15px;
        line-height: 1.25;
        padding: 10px 15px 12px;
        transition: $time linear;

        @include hover {
            background-color: $c-grey;
        }

        &.-current {
            color: $c-default;
            background-color: $c-lgrey;
        }

        &.__disabled {
            color: $c-dgrey;
        }
    }
}

.form-checkbox {
    display: none;

    &-label {
        display: inline-block;
        cursor: pointer;
        font-size: 16px;
        line-height: 24px;
        position: relative;
        padding-left: 30px;
        transition: $time linear;
        vertical-align: middle;

        &:before {
            background-color: $c-none;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 10px 8px;
            border: solid 2px $c-dgrey;
            border-radius: 2px;
            content: '';
            height: 20px;
            left: 0;
            position: absolute;
            top: 4px;
            transition: $time linear;
            width: 20px;
        }

        &:hover:before {
            border: solid 2px $c-corp;
        }

        @include hover {
            color: $c-bread;
        }

        .error & {
            &::before {
                border-color: rgba($c-corp,.5);
            }

            &:hover::before {
                border-color: $c-corp;
            }
        }
    }

    &:checked {
        & + .form-checkbox-label {
            &:before {
                background-color: $c-corp;
                background-image: url(#{$assets-url}/images/svg/check.svg);
                border: solid 2px $c-corp;
            }
        }
    }
}

.form-range {
    padding: 0 10px 0 0;
    margin-top: 5px;
    margin-bottom: 20px;

    &-amount {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        min-height: 0;
        margin-bottom: 20px;

        font-size: 14px;

        &-txt {
            padding-left: 20px;

            &.__hyphen {
                width: 20px;
                text-align: center;
                padding-left: 0;
            }
        }

        &-from,
        &-to {
            width: calc(50% - 30px);
            max-width: 76px;
            height: 30px;
            padding: 0 8px;

            font-size: inherit;
            line-height: 28px;
            text-indent: 0;
            text-align: left;
            outline: 0 none;

            border: 1px solid $c-dgrey;
            border-radius: 2px;
            background-color: $c-light;
        }
    }

    &-slider {
        position: relative;
        height: 6px;
        border-radius: 2px;
        background: rgba(#d3d3d3, .3);

        &-container {
            position: relative;
            width: 100%;
        }

        &.ui-widget.ui-widget-content {
            border: 0;
        }
    }

    .ui-slider-handle {
        position: absolute;
        top: -7px;

        width: 20px;
        height: 20px;
        margin-left: -10px;
        z-index: 2;

        border-radius: 50%;
        border: 5px solid $c-slider-btn;
        background: none $c-lgrey;

        touch-action: none;
        outline: 0 none;
        cursor: pointer;
        transition: border $time linear;


        @include media-breakpoint-down(sm) {
            border: 5px solid $c-dark;
        }

        &:hover {
            border: 5px solid $c-dark;
        }
    }
}

.form-radio {
    display: none;

    &-label {
        font-size: 16px;
        cursor: pointer;
        display: inline-block;
        line-height: 24px;
        padding-left: 30px;
        position: relative;
        transition: $time linear;
        vertical-align: middle;

        &::before {
            background-color: $c-none;
            border: solid 2px $c-dgrey;
            border-radius: 100%;
            content: '';
            height: 20px;
            left: 0;
            position: absolute;
            top: 4px;
            transition: $time / 2 linear;
            width: 20px;
        }

        &:hover::before {
            border-color: $c-corp;
        }

        @include hover {
            color: $c-bread;
        }

        span {
            font-size: 14px;
        }

        .error & {
            &::before {
                border-color: rgba($c-corp,.5);
            }

            &:hover::before {
                border-color: $c-corp;
            }
        }
    }

    &:checked {
        & + .form-radio-label {
            &::before {
                border: solid 6px $c-corp;
            }
        }
    }

    &-wrapper {
        display: flex;
        flex-direction: column;

        @include sm {
            flex-direction: row;
        }
    }

    &-container {
        margin-bottom: 15px;

        @include sm {
            margin-bottom: 0;
            margin-right: 30px;
        }

        &:last-child {
            margin-bottom: 0;

            @include sm {
                margin-right: 0;
            }
        }
    }
}

.form-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    input {
        display:none;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $c-dgrey;
        -webkit-transition: $time;
        transition: $time;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: $c-light;
        -webkit-transition: $time;
        transition: $time;
    }

    input:checked + .slider {
        background-color: $c-exclusive;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px $c-exclusive;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
}

.form-input-file {
    background-color: $c-none;
    border-bottom: solid 1px $c-dgrey;
    color: $c-bread;
    font-size: 16px;
    height: 40px;
    overflow: hidden;
    transition: $time ease-in-out;
    width: 100%;

    @include hover {
        border-bottom: solid 2px $c-dgrey;
        outline: none;
    }

    &-upload {
        display: none;

        input {
            display: none;
        }

        label {
            cursor: pointer;
            left: 0;
            line-height: 40px;
            padding-left: 30px;
            position: absolute;
            top: 0;
            width: 100%;

            &::before {
                background-image: url(#{$assets-url}/images/svg/attach.svg);
                content: '';
                height: 11px;
                left: 0;
                position: absolute;
                top: 16px;
                width: 20px;
            }

            @include hover {
                color: $c-default;

                &::before {
                    background-image: url(#{$assets-url}/images/svg/attached.svg);
                }
            }
        }
    }

    &-uploaded {
        display: flex;
    }

    &-delete {
        flex-shrink: 0;

        a {
            background-image: url(#{$assets-url}/images/svg/delete-file.svg);
            background-size: 10px 10px;
            background-position: 0 17px;
            background-repeat: no-repeat;
            display: block;
            height: 40px;
            width: 20px;
        }
    }

    &-link {
        a {
            display: block;
            color: $cLink;
            cursor: default;
            line-height: 40px;
            width: 100%;
        }
    }
}
