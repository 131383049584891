.left-panel {
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    bottom: 0;
    width: 60px;
    background-color: $c-corp;

    a {
        display: block;
        height: 60px;
        margin-left: -46px;
        margin-top: 46px;
        padding-top: 20px;
        position: fixed;
        text-align: center;
        transform: rotate(-90deg);
        width: 151px;
    }

    .logo-d {
        height: 20px;
        width: 101px;

        .logo-symbols {
            fill: $c-light;
        }
    }
}
