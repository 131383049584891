// Imports global variables
@import "vars-global";

//Grid columns
$grid-columns: 12;
$grid-gutter-width: 20px;

// Bootstrap Settings
@import '../../../../bower_components/bootstrap/scss/functions';
@import '../../../../bower_components/bootstrap/scss/variables';
@import '../../../../bower_components/bootstrap/scss/mixins';
@import '../../../../bower_components/bootstrap/scss/root';
@import '../../../../bower_components/bootstrap/scss/reboot';
@import '../../../../bower_components/bootstrap/scss/grid';
@import '../../../../bower_components/bootstrap/scss/transitions';
@import '../../../../bower_components/bootstrap/scss/nav';
@import '../../../../bower_components/bootstrap/scss/close';
@import '../../../../bower_components/bootstrap/scss/modal';
@import '../../../../bower_components/bootstrap/scss/utilities';

// Change .container to sm: 100%, md: 100%, lg: 100%, xl: 1140px
.container {
    @each $breakpoint, $container-max-width in $container-max-widths {
        @if $breakpoint != 'xl' {
            @include media-breakpoint-down($breakpoint, $grid-breakpoints) {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}
