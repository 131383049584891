.footer {
    color: $c-light;
    padding: 142px 0 35px;
    width: 100%;

    @include media-breakpoint-down(lg) {
        padding: 92px 0 25px;
    }

    @include media-breakpoint-down(md) {
        padding: 52px 0 0;
    }

    li {
        display: inline-block;
        line-height: 1em;
        white-space: nowrap;
    }

    a {
        color: $c-light;

        @include hover {
            color: $c-light;
        }
    }

    &-main {
        li {
            margin-right: 0;
            width: 100%;

            @include md {
                margin-right: 58px;
                width: auto;

                &:last-child {
                    margin-right: 20px;
                }
            }
        }

        a {
            margin-bottom: 12px;

            @include md {
                margin-bottom: 22px;
            }
        }
    }

    &-link {
        background: $c-none;
        border: none;
        display: inline-flex;
        justify-content: flex-start;
        margin-bottom: 22px;

        .__icon {
            margin-right: 18px;
            position: relative;
            width: 34px;
            height: 32px;
            font-size: 32px;
        }

        .__text {
            font-size: 1.6em;
            height: 32px;
            line-height: 25px;
            position: relative;

            &::after {
                border-bottom: 1px solid;
                content: '';
                height: 2px;
                left: 0;
                position: absolute;
                top: 24px;
                transition: $time ease-in-out;
                width: 100%;
            }
        }

        i {
            line-height: inherit;
            margin-left: 10px;
            transition: all $time;
        }

        @include hover {
            .__text {
                &::after {
                    border-color: transparent;
                }
            }
        }
    }

    &-contacts {
        align-items: center;
        font-size: 1.6em;
        margin-top: 25px;
        margin-bottom: 25px;

        @include media-breakpoint-down(sm) {
            text-align: center;
        }

        li {
            font-size: 45px;

            @include media-breakpoint-down(md) {
                font-size: 36px;
            }

            @include media-breakpoint-down(sm) {
                font-size: 50px;
            }

        }

        li + li {
            margin-left: 28px;
        }

        &-social {
            @include media-breakpoint-down(sm) {
                display: flex;
                justify-content: space-evenly;
            }
        }

        .phone {
            &-active {
                margin-bottom: 32px;
                margin-top: 32px;
                text-align: center;

                a {
                    display: block;
                    width: 100%;
                    height: 50px;

                    background-color: $c-dark;
                    color: #fff !important;
                    line-height: 50px;

                    @include hover {
                        color: #fff !important;
                    }
                }

                span {
                    margin-left: 7px;
                }

            }
        }
    }

    &-info {
        nav {
            padding-top: 25px;
            padding-bottom: 15px;
            border-bottom: rgba(255, 255, 255, 0.5) solid 1px;
        }
    }

    &-subcategories {
        cursor: pointer;

        @include media-breakpoint-down(sm) {
            padding-top: 0;
        }

        @include md {
            position: relative;
        }

        > .footer-link {

            @include md {
                margin-bottom: 0;
            }
        }

        &.__open {
            > .footer-link .__text i {
                transform: scaleY(-1);
            }
        }

        &-list {
            @include media-breakpoint-down(sm) {
                display: none;
            }

            li {
                display: block;
                margin-left: 52px;
                transition: all $time;
                width: 50%;

                @include md {
                    width: 100%;
                }

                .footer-link {
                    @include md {
                        margin-bottom: 0;
                    }

                    &:hover {
                        color: $c-light;
                    }

                    .__text {
                        @include md {
                            font-size: 14px;
                            line-height: 32px;
                        }
                    }
                }
            }
        }
    }

    &-nav {
        box-sizing: content-box;
        font-size: 1.3em;
        padding-top: 35px;

        @include media-breakpoint-down(md) {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            max-height: 120px;
            justify-content: flex-start;
        }

        li {
            @include media-breakpoint-down(md) {
                margin-bottom: 16px;
            }
        }

        > li + li {
            margin-left: 32px;

            @include media-breakpoint-down(md) {
                margin-left: 0;
            }
        }

        > li:nth-child(4) {
            order: 4;

            @include media-breakpoint-down(md) {
                order: 5;
            }
        }

        > li:nth-child(5) {
            order: 5;

            @include media-breakpoint-down(md) {
                order: 6;
            }
        }

        &-lang {
            position: relative;
            cursor: pointer;

            @include media-breakpoint-down(md) {
                order: 4;
            }

            i {
                margin-left: 8px;
            }

            &-list {
                background-color: $c-corp;
                border: solid 1px;
                bottom: calc(100% + 3px);
                display: none;
                left: -15px;
                position: absolute;

                li {
                    display: block;
                    margin-bottom: 0;
                    margin-left: 0;

                    a {
                        display: block;
                        height: 34px;
                        line-height: 34px;
                        padding: 0 15px;
                        transition: all $time ease-in-out;

                        @include hover {
                            background-color: $c-light;
                            color: $c-default;
                        }
                    }
                }
            }
        }
    }
}