.bouncing-loader {
    align-items: center;
    display: flex;
    justify-content: center;

    > div {
        width: 1rem;
        height: 1rem;
        margin: 0 0.2rem;
        background: red;
        border-radius: 50%;
        animation: bouncing-loader 0.6s infinite alternate;

        &:nth-child(2) {
            animation-delay: 0.2s;
        }

        &:nth-child(3) {
            animation-delay: 0.4s;
        }
    }
}

@keyframes bouncing-loader {
    to {
        opacity: 0.1;
        transform: translate3d(0, -1rem, 0);
    }
}