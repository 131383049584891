.link-to-top {
    background-color: $c-corp;
    border: 1px solid $c-light;
    border-radius: 100%;
    bottom: 50px;
    color: $c-light;
    cursor: pointer;
    display: none;
    font-size: 33px;
    height: 40px;
    line-height: 20px;
    opacity: .8;
    padding: 2px;
    position: fixed;
    right: 20px;
    text-align: center;
    transition: $time ease-in-out;
    width: 40px;
    z-index: 149;

    @include sm {
        font-size: 39px;
        height: 48px;
        line-height: 24px;
        right: 20px;
        width: 48px;
    }

    @include md {
        bottom: 70px;
        right: 15px;
    }

    @include lg {
        font-size: 46px;
        height: 56px;
        line-height: 28px;
        width: 56px;
    }

    &:hover {
        opacity: 1;
    }
}
